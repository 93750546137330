import React from "react";
import "./Donate.css";

const Donate = () => {
  return (
    <div id="donate" className="section-components-1">
      <h1>Donate</h1>
      <a className="btn-donate-page" href="https://checkout.square.site/merchant/ML4Q9Z31KFNYN/checkout/RQW2CFDAWIQPCBZAAJU6FI5G">DONATE NOW</a>
      <div>
        <p>You can also support us via bank transfer or standing order:</p>
        <p className="account-deets"><span className="donate-headers">Name:</span> BEACON INSTITUTE SWANSEA<br/><br/><span className="donate-headers">Account number:</span> 71802975<br/><br/><span className="donate-headers">Sort code:</span> 51-61-15</p>
      </div>
      
    </div>
  );
};

export default Donate;
